.introSection {
  color: white;
}

@keyframes leafColorChange {
  0% {
    background-color: red;
  }
  40% {
    background-color: yellow;
  }
  80% {
    background-color: brown;
  }
}

@keyframes leafSlideIn {
  0% {
    transform: translate(20vw);
  }
  100% {
    transform: none;
  }
}

.introLeaf {
  width: 100px;
  height: 100px;
  -webkit-mask-image: url("../assets/leaf.svg");
  mask-image: url("../assets/leaf.svg");
  mask-size: 100%;
  animation: 4s leafSlideIn, leafColorChange 4s infinite;
}
