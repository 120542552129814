.summer {
  background-color: rgba(220, 75, 75, 0.884);
}

.sun {
  width: 50px;
  height: 50px;
  border-radius: 100%;
  background-color: yellow;
  position: relative;
  top: 0%;
  left: 100%;
}

@keyframes sunRayMove {
  0% {
    translate: 40px -10px;
  }
  100% {
    translate: none;
  }
}

.sunray {
  border: 1px solid yellow;
  height: 5px;
  background: yellow;
  position: absolute;
  animation: 4s sunRayMove infinite;
}

.ray1 {
  top: 60%;
  left: -20%;
  transform: rotate(45deg);
}

.ray2 {
  top: 85%;
  left: -20%;
  transform: rotate(45deg);
}

.ray3 {
  top: 100%;
  transform: rotate(45deg);
}
