.spring {
  background-color: rgba(152, 220, 75, 0.884);
}

@keyframes waveLeft {
  100% {
    transform: rotate(-100deg);
    transform-origin: bottom left;
  }
  0% {
    transform: rotate(-40deg);
    transform-origin: bottom left;
  }
}

@keyframes waveRight {
  100% {
    transform: rotate(-10deg);
    transform-origin: bottom left;
  }
  0% {
    transform: rotate(-40deg);
    transform-origin: bottom left;
  }
}

.spring .rightLeaf {
  animation: 4s waveRight infinite forwards;
}

.spring .leftLeaf {
  animation: 4s waveLeft infinite forwards;
}
