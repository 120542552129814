.section {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
}

@media (max-width: 768px) {
  .section {
    flex-direction: column;
  }
}

.textHidden {
  visibility: hidden;
}

@keyframes leftMove {
  0% {
    translate: -50vh;
  }
  100% {
    translate: none;
  }
}

.leftContent {
  animation: 1s leftMove;
}

@keyframes rightMove {
  0% {
    translate: 50vh;
  }
  100% {
    translate: none;
  }
}

.rightContent {
  animation: 1s rightMove;
}

.graphics {
  padding: 10vw;
}
