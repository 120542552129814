.fall {
  background-color: rgba(220, 148, 75, 0.884);
}

@keyframes leftLeafFall {
  100% {
    top: 150%;
    transform: rotate(-135deg);
  }
}

@keyframes rightLeafFall {
  100% {
    top: 150%;
    transform: rotate(45deg);
  }
}

.fall .leftLeaf {
  animation: 4s leftLeafFall infinite;
}

.fall .rightLeaf {
  animation: 4s rightLeafFall infinite;
}
