.winter {
  background-color: rgba(75, 220, 191, 0.884);
}

.snowflakes {
  position: relative;
  z-index: +1;
}

@keyframes flakeFall {
  100% {
    transform: translateY(180px);
  }
}

.snowflake {
  background: white;
  mask-image: url("../assets/snowflake.svg");
  mask-size: 100%;
  width: 15px;
  height: 15px;
  position: absolute;
  animation: 4s flakeFall infinite;
}

.flake1 {
  left: 20%;
}

.flake2 {
  left: 40%;
}

.flake3 {
  left: 60%;
}

.flake4 {
  left: 80%;
}
