.tree {
  width: 200px;
  height: 200px;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
}

.leaves {
  width: 100%;
  height: 50%;
  position: relative;
}

.leaf {
  width: 40px;
  height: 40px;
  background-color: green;
  border-radius: 100% 0 100% 0;
  position: absolute;
  z-index: +1;
}

.one {
  /* top: 75%;
    left: 11%;
    transform: rotate(30deg); */

  top: 65%;
  left: 28%;
  /* transform-origin: bottom left;
      transform: rotate(210deg); */
}

/* .log::after { 
    width : 15px;
    height: 30px; 
    background-color: brown;
    position: absolute;
    /* top: 70%;
    left : 15%;
    transform: rotate(50deg); */
/*} */

.two {
  /* top: 45%;
    left : 20%;
    transform: rotate(120deg); */

  top: 65%;
  left: 28%;
  /* transform-origin: bottom left;
      transform: rotate(300deg); */
}

.three {
  /* top: 15%;
      left: 30%;
      transform: rotate(90deg); */

  top: 15%;
  left: 50%;
  /* transform-origin: bottom left;
      transform: rotate(270deg); */
}

.four {
  /* top: 15%;
      left: 50%;
      transform: rotate(180deg); */

  top: 15%;
  left: 50%;
  /* transform-origin: bottom left;
      transform: rotate(0deg); */
}

.five {
  /* top: 45%;
    right: 20%;
    transform: rotate(145deg); */

  top: 65%;
  right: 8%;
  /* transform-origin: bottom left;
      transform: rotate(330deg); */
}

.six {
  /* top: 75%;
      right: 15%;
      transform: rotate(270deg); */

  top: 65%;
  right: 8%;
  /* transform-origin: bottom left;
      transform: rotate(60deg); */
}

.leftLeaf {
  transform: rotate(-100deg);
  transform-origin: bottom left;
}

.rightLeaf {
  transform: rotate(-10deg);
  transform-origin: bottom left;
}

.treeBase {
  width: 100%;
  height: 50%;
  position: relative;
}

.log {
  height: 100px;
  width: 30px;
  background-color: brown;
  position: relative;
  margin: 0 auto;
}

.twigOne {
  width: 20px;
  height: 50px;
  background-color: brown;
  position: absolute;
  transform: rotate(100deg);
  top: -25px;
  left: -15px;
}

.twigTwo {
  width: 20px;
  height: 50px;
  background-color: brown;
  position: absolute;
  transform: rotate(180deg);
  top: -50px;
  left: 5px;
}

.twigThree {
  width: 20px;
  height: 50px;
  background-color: brown;
  position: absolute;
  transform: rotate(260deg);
  top: -25px;
  right: -15px;
}

/* .log::after {
    width: 15px;
      height: 30px;
      background-color: brown;
      position: absolute;
  } */
/* .springTree {
    width: 100px;
    height:100px;
    -webkit-mask-image: url("./springTree.svg");
    mask-image: url("./springTree.svg");
    mask-size: 10%;
    
    /* background-image: url("./springTree.svg"); */
/* animation: 4s slide, fillAnimation 4s forwards infinite ; */
/*
  } 
  */
